import React, { Component, useState } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { useRouteError, createBrowserRouter, useLocation, Outlet, Link as RouterLink, useNavigate} from "react-router-dom";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/ja';

import { PrimeReactProvider } from 'primereact/api';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AppsIcon from '@mui/icons-material/Apps';
import ReplyIcon from '@mui/icons-material/Reply';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import LightModeIcon from '@mui/icons-material/LightMode';
import Logout from '@mui/icons-material/Logout';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Avatar from '@mui/material/Avatar';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Settings from '@mui/icons-material/Settings';
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import WorkflowModule from "./components/workflow/workflow";
import WorkflowFrontModule from "components/workflow/workflow-front";
import ManagementModule from "./components/management";
import DashboardsModule from "./components/dashboards";
import SecurityModule from "./components/security";
import DlibModule from "components/dlib/dlib";
import postal from 'postal';
import Login from "./components/login";

import { SocketClient, netSocketSend, UserInfo, keycloak, netGet, netPost, registerInitHook } from "./components/network";

import 'primereact/resources/themes/lara-light-blue/theme.css';
import './App.css';
import { PreferencesDialog } from './components/preferences';
import { PdftronWebViewer, pdftronInstance} from "./components/webviewer";

// we need the redux store directly to access the dispatch function outside React components
// this is OK as this is the store connected to our React root
import store from './app/store';
import { setUser, setSessionId, setAuthenticatedAsync } from './features/network/networkSlice';
import { setPageIndex as reduxSetPageIndex } from './features/menu/menuSlice';

//  import theme from "assets/theme"; // theme from dashboards

const DRAWER_WIDTH = 260;


/*
console.log('protocol=' + window.location.protocol + ' : ' + wsproto);
console.log('hostname=' + window.location.hostname);
console.log('port=' + window.location.port);
*/

/*
const wsheaders = {
  'Connection': 'Upgrade',
  'Upgrade': 'websocket',
};
getFetch(window.location.protocol + '//' + serverPath).then(response => {}, wsheaders );
*/





const Notifications = {
  count: 0,
  items: []
};

const userInfo  = (message) => {
  const info = message.data;
  if ( info.sessionId ) {
    UserInfo.info['sessionId'] = info.sessionId;
    store.dispatch(setSessionId(info.sessionId));
  }
  console.log('Received user message: ' + UserInfo.info.email + ' : ' + UserInfo.info.sessionId);
};

// define the application router configuration, modules will add their bit later and then we'll create the router
// routerConfig[0] is the root
const routerConfig = [
  {
    path: "/",
    element: <App/> ,
    errorElement: <ErrorPage />,
    children: [],
  },
];

const DEFAULT_ROUTE = '/workflow';


/*
  Message hooks (message listeners) are indexed by message type, so some filtering is done prior to passing messages around.
  Each hook type is a map indexed by hook name with value a function that receives the 
  JSON data (already parsed) from the message payload.
  When adding hooks from other modules do:
      MessageHooks["messageType"]["myHookName"] = myMessageHandlingFunction;
  We do it this way, with a hook name, rather just an array, to avoid bugs of pushing the same hook
  many times (infinite times causing stack overflow) if the caller is misbehaved.
*/
const MessageHooks = {
  userinfo: { App: userInfo }, // App is the hook name chosen for this module, and userInfo is the function to handle the message
  workflow: {},
  dlib: {},
};

const Modules = [];
const locationModule = {};

// register modules
// Modules.push(WorkflowFrontModule);
Modules.push(WorkflowModule);
Modules.push(ManagementModule);
//Modules.push(DashboardsModule);
// Modules.push(SecurityModule);
Modules.push(DlibModule);

// Add message hooks and routes in modules
for(const m of Modules) {
  if ( m.hooks) {
    for(const mtype in m.hooks ) {
      if ( ! mtype in MessageHooks ) {
        MessageHooks[mtype] = {};
      }
      for(const hname in m.hooks[mtype] ) {
        if ( typeof m.hooks[mtype][hname] === 'function') {
          MessageHooks[mtype][hname] = m.hooks[mtype][hname];
        }
      }
    }
  }
  if ( m.route)  {
      //console.log('Adding route ' + JSON.stringify(m.route));
      routerConfig[0].children.push(m.route);
      if ( m.route.path ) {
        locationModule[m.route.path] = m;
      }
  }
}

// console.log('locationModule: ' + JSON.stringify(locationModule));


// add the collab hook, since there's no menu module for it
MessageHooks['collab'] = {};

// create the application router
const router = createBrowserRouter(routerConfig);

const processServerMessage = (message) => {
  /*
  if (message.type === 'userinfo') {
    UserInfo.info = message.data;
    console.log('Received user data: ' + UserInfo.info.email);
  }
  */
  console.log('RECEIVED SERVER MESSAGE: ' );
  if ( message.type != 'workflow' ) {
    console.log(JSON.stringify(message));
  }

  if (MessageHooks[message.type]) {
    const hooks = MessageHooks[message.type];
    for (const hookName in hooks) {
      if (typeof hooks[hookName] === 'function') {
        hooks[hookName](message);
      }
    }
  }
};



/*
const SocketClient = new W3CWebSocket(serverUrl, 'ucs');
SocketClient.onopen = () => {
  console.log('WebSocket Client Connected');
  // terminate polling
  try {
    window.clearInterval(pollingIntervalInst);
  } catch (ex) {
    // do nothing
  }
};
SocketClient.onmessage = onServerMessage;
SocketClient.onerror = (error) => {
  console.log('WebSocket error: ' + error);
  console.log('Falling back to polling...')
  try {
    window.clearInterval(pollingIntervalInst);
  } catch (ex) {
    // do nothing
  }
  pollingIntervalInst = window.setInterval(pollServer, 3300);
}
*/

// registerInitHook('getuser', () => {
  // it's safe to call this early as we're now doing hybrid authentication and the server will
  // authenticate first if needed
  netGet('/api/auth/user')
    .then(response => response.json())
    .then(data => {
      // Direct call may not contain a sessionId
      // as they come from the socket server
      // try to preserve it if we already have one
      let sid;
      if (UserInfo.info) {
        sid = UserInfo.info.sessionId;
      }
      // console.log('Got user = ' + JSON.stringify(data));
      UserInfo.info = data;
      store.dispatch(setUser(data));
      if (!UserInfo.info.sessionId) {
        UserInfo.info.sessionId = sid;
        if ( sid ) {
          store.dispatch(setSessionId(sid));
        }
      }
      console.log('Fetched user data: ' + UserInfo.info.email + ' : ' + UserInfo.info.sessionId);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
// });

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{height: '100vh', display: value !== index ? 'none' : 'block'}}
      role="tabpanel"
      id={`app-tabpanel-${index}`}
      aria-labelledby={`app-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>
          {children}
        </React.Fragment>
      )}
    </div>
  );
}

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

function App() {
  const [page, setPage] = React.useState(null);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  // const [drawerContent, setDrawerContent] = React.useState(Modules[0].drawerContent);
  const [drawerContent, setDrawerContent] = React.useState(<Outlet/>);
  // const [drawerContent, setDrawerContent] = React.useState(null);
  const [module, setModule] = React.useState(null); // Modules[0]
  //const [module, setModule] = React.useState(null);
  const [menuIcon, setMenuIcon] = React.useState('app');
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const [drawerWidth, setDrawerWidth] = useState(DRAWER_WIDTH);

  // GLOBAL THEME, edit here


  const theme = createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    /*
    styleOverrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          },
        }
      }
    }
    */
  });


  const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const [barTitle, setBarTitle] = React.useState(matchesDesktop ? Modules[0].barLabel : Modules[0].barShortLabel);
 
  

  React.useEffect(() => {

    /*
    netGet('/api/auth/user')
      .then(response => response.json())
      .then(data => {
        UserInfo.info = data;
        console.log('Fetched user data: ' + UserInfo.info.email);
      });
    */
    console.log('Initializing App...');
    /* not needed anymore
    netGet('/api/auth/login').then(response => {
      if ( !response.ok ) {
        console.log('Login failed with status = ' + response.status);
      }
    });
    */

    changeModule(Modules[0]);


    const subscription = postal.subscribe({
      topic: "app.page.change.index",
      callback: (data, envelope) => {
          // console.log('Got message: ' + JSON.stringify(data) + ',' + JSON.stringify(envelope));
          handlePostalPageChangeIndex(data);
      }});
    
    return () => {
      subscription.unsubscribe();
    }
  }, []);

  /* not needed anymore, app will use server side authorization flow just after login while the frontend gets ready.
     After frontend gets connected, the access token is maintained in the frontend
  registerInitHook('AppTopModule', () => {
    initReady = true;
    changeModule(Modules[0]);
  });
  */

  React.useEffect(() => {
    // console.log('location changed: ' + JSON.stringify(location));
    const pn = location.pathname;
    const path = pn.startsWith('/') ? pn.substring(1) : pn;
    if ( locationModule[path] ) {
      handleModuleChange(locationModule[path]);
    } else if ( pn === '/' && pn !== DEFAULT_ROUTE) {
      navigate(DEFAULT_ROUTE);
    }
  }, [location]);

  const handlePostalPageChangeIndex = (msg) => {
    // console.log('Got pageIndexChange message: ' + JSON.stringify(msg));
    if ( typeof msg.index !== 'undefined' ) {
      handlePageIndexChange(msg.index);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    // setting the page resets the page index
    handlePageIndexChange(0);
  };

  const handlePageIndexChange = (index) => {
    setPageIndex(index);
    // need to do it this way, otherwise like adding code to the display style in <div/> 
    // seems to cause React to remove element from DOM and add it again with new property,
    // which causes the PdfViewer to be re-instantiated.
    let tab = document.getElementById('app-tabpanel-0');
    if ( tab ) {
      if ( index === 0 ) {
        tab.style.display = 'block';
      } else {
        tab.style.display = 'none';
      }
    }
    tab = document.getElementById('app-tabpanel-1');
    if ( tab ) {
      if ( index === 1 ) {
        tab.style.display = 'block';
      } else {
        tab.style.display = 'none';
      }
    }
    postal.publish({
      topic: "app.page.change.index.notify",
      data: {
          index: index
      }
    });
    // also send it to redux
    // (TODO: we should remove postal and use only redux!)
    store.dispatch(reduxSetPageIndex(index));
  };

  const handleDrawerOpen = (open) => {
    setDrawerOpen(open);
  };

  const handleMenuIconChange = (icon) => {
    setMenuIcon(icon);
  };

  const handleDrawerChange = (drawer) => {
    setDrawerContent(drawer);
  }

  const changeModule = (module) => {
    // console.log('Module change: ' + module.label);
    module.pageChange = handlePageChange;
    module.pageIndexChange = handlePageIndexChange;
    module.drawerOpen = handleDrawerOpen;
    module.drawerChange = handleDrawerChange;
    module.menuIconChange = handleMenuIconChange;
    let dw = DRAWER_WIDTH;
    if ( module.drawerWidth ) {
      dw = module.drawerWidth;
    }
    console.log("DRAWER WIDTH = " + dw);
    setDrawerWidth(dw);
  };

  const handleModuleChange = (module) => {
    // console.log('Handle module change: ' + JSON.stringify(module));
    if ( module ) {
      console.log('Module change: ' + module.label);
      changeModule(module);
      setModule(module);
      // setDrawerContent(module.drawerContent);
      setPage(module.pageContent);
      setBarTitle(matchesDesktop ? module.barLabel : module.barShortLabel);
      postal.publish({
        topic: "app.menu.change",
        data: {
            menu: module.name,
        }
    });
    }
  };

  const handleMenuIconClick = (event) => {
    if ( module ) {
      module.onMenuIconClick(event);
    }
  };

  

  return (
    <ThemeProvider theme={theme}>
      { !isAuthenticated ? <Login/> :
        <PrimeReactProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <Box className="ucs-app" sx={{
              bgcolor: 'background.default',
              color: 'text.primary',
            }}>
              <Stack sx={{ height: '100%' }}>
                <UcsHeader title={barTitle}
                  menuIcon={menuIcon}
                  onAppChange={handleModuleChange}
                  onMenuIconClick={handleMenuIconClick}
                  onClose={() => { }}
                  onExit={() => { }}
                  drawerOpen={drawerOpen}
                  drawerContent={drawerContent}
                  drawerWidth={drawerWidth}
                  onOpenDrawer={(dopen) => { setDrawerOpen(dopen) }} >
                  <Box className="ucs-body">
                    <Box id='app-tabpanel-0' sx={{ display: 'block', height: '100%' }}>
                      {page}
                    </Box>
                    <Box id='app-tabpanel-1' sx={{ display: 'none', height: '100%' }}>
                      <PdftronWebViewer />
                    </Box>
                  </Box>
                </UcsHeader>
              </Stack>
            </Box>
          </LocalizationProvider>
        </PrimeReactProvider>
      }
    </ThemeProvider>
  );
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, drawerwidth }) => ({
    flexGrow: 1,
    height: '100%',
    padding: theme.spacing(0),
    paddingTop: '56px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerwidth}px`,
    }),
  }),
);


function UcsHeader(props) {
  const {
    onClose,
    menuIcon,
    onMenuIconClick,
    onAppChange,
    onExit,
    drawerOpen: extDrawerOpen,
    drawerContent,
    onOpenDrawer,
    drawerWidth,
    ...other
  } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [prefOpen, setPrefOpen] = React.useState(false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);
  const [appMenuAnchorEl, setAppMenuAnchorEl] = React.useState(null);
  const [moduleMenuAccess, setModuleMenuAccess] = React.useState({});
  const theme = useTheme();
  const matchesDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const openUserMenu = Boolean(userMenuAnchorEl);
  const openAppMenu = Boolean(appMenuAnchorEl);
  const networkUser = useSelector((state) => state.user);
  // const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const isFormEnabled = useSelector((state) => state.auth.formEnabled);
  
  const dispatch = useDispatch();

  const appIcon = <span className="ucs-logo"><img src="/images/application.svg" width="40" style={{ paddingLeft: "0px"}}/></span> ;
  const appMenuIcon = <MenuIcon/>;

  React.useEffect( () => {
    const mma = { ...moduleMenuAccess};
    Modules.forEach( module => {
      netPost('/api/auth/acl/eval/multi', {
        params: [
          {
            path: module.aclPaths[0].path,
            action: 'BROWSE',
            subjects: UserInfo.info.roles,
          },
          {
            path: module.aclPaths[0].path,
            action: 'READ',
            subjects: UserInfo.info.roles,
          }
        ]
      }).then(response => {
        if (!response.ok) {
          throw new Error('Status: ' + response.status);
        }
        return response.json();
      })
        .then(data => {
          // console.log(`ACL Result [${module.aclPaths[0].path}] = ` + JSON.stringify(data));
          if ( Array.isArray(data) ) {
            
            mma[module.name] = {
              hide: !data[0].result,
              disabled: !data[1].result,
            };
            setModuleMenuAccess(mma);
          }
          
        }).catch(error => {
          console.log('Error: ' + error);
        });
    });

  },[]);

  React.useEffect(() => {
    if ( !matchesDesktop ) {
      setDrawerOpen(extDrawerOpen);
    }
  }, [extDrawerOpen])

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleAppMenuClick = (event) => {
    setAppMenuAnchorEl(event.currentTarget);
  };

  const handleAppMenuClose = () => {
    setAppMenuAnchorEl(null);
  };

  const handleAppModuleClick = (module,index) => {
    console.log('Changing to module ' + module + ' ' + index);
    if ( onAppChange ) {
      onAppChange(module,index);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
    if ( onOpenDrawer ) {
      onOpenDrawer(open);
    }
    if ( onMenuIconClick ) {
      onMenuIconClick(event);
    }
  };

  const handleMobileDrawerToggle = () => {
    setDrawerOpen(false);
    if ( onOpenDrawer ) {
      onOpenDrawer(false);
    }
  };

  const handlePrefClick = (event) => {
    setPrefOpen(true);
  };

  const handlePrefClose = (event) => {
    setPrefOpen(false);
  };

  const handleLogout = () => {
    if (isFormEnabled) {
      // delete the credential cookie, essentially killing the session
      const removeCookie = `quarkus-credential=; Max-Age=0;path=/`;
      document.cookie = removeCookie;
      dispatch(setAuthenticatedAsync(false));
    } else {
      keycloak.logout({ redirectUri: window.location.href });
    }
  };

 

  return (
    <Box sx={{ flexGrow: 0, width: "100%", height: '100%' }} className="ucs-header">
      <CssBaseline />
      <AppBar position="absolute" sx={{ display: "block", backgroundColor: "#f5f7f8", color: "black", paddingRight: 0, zIndex: (theme) => theme.zIndex.drawer + 1, minHeight: '54px'}}>
        <Toolbar variant="dense" disableGutters={true} sx={{ paddingLeft: "10px", maxWidth: "100%", minHeight: '54px'}}>
          <IconButton
            onClick={toggleDrawer(drawerOpen ? false : true)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ 
              mr: 1, 
              color: "#3c73aa", 
              fontSize: "2rem",
              padding: '8px',
              paddingRight: 0 }}
          > 
            <Box sx={{ display: 'inline-block', width: '40px' }}>{ menuIcon === 'back' ? 
              <ReplyIcon/> :
              appIcon
            }</Box>
          </IconButton>
          <Typography component="div" sx={{ flexGrow: 1, fontSize: "18px", fontWeight: "bold" }}>
            {props.title}
          </Typography>
          <IconButton
            onClick={handleAppMenuClick}
            sx={{ mr: 2, 
              color: "#3c73aa", 
              fontSize: "2rem",
              [theme.breakpoints.down('md')]: {
                marginRight: '0px',
              },
            }}
          >
            <AppsIcon fontSize="inherit"/>
          </IconButton>
          <IconButton
            sx={{
              mr: 2,
              color: "#3c73aa",
              fontSize: "2rem",
              [theme.breakpoints.down('md')]: {
                marginRight: '0px',
              },
            }}
          >
            <Badge badgeContent={Notifications.count} color="error" overlap="circular">
              <NotificationsNoneIcon fontSize="inherit"/>
            </Badge>
          </IconButton>
          <IconButton
            onClick={handleUserMenuClick}
            sx={{
              mr: 2,
              color: "#3c73aa",
              fontSize: "2rem",
              [theme.breakpoints.down('md')]: {
                marginRight: '8px',
              },
            }}
          >
            <span className="ucs-logo" style={{position: "relative", top: "6px"}}><img src="/images/user-02.svg" width="32" /></span>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        position="absolute"
        variant="temporary"
        open={extDrawerOpen}
        onClose={handleMobileDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          width: "100%",
          position: "absolute",
          paddingRight: "8px",
          [`& .MuiDrawer-paper`]: { width: "100%", boxSizing: 'border-box', position: "absolute" },
        }}
      >
        <Toolbar variant="dense" disableGutters={true} sx={{minHeight: '54px'}}></Toolbar>
        {drawerContent}
      </Drawer>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          display: { xs: 'none', sm: 'block' },
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar variant="dense" disableGutters={true} ></Toolbar>
        {drawerContent}
      </Drawer>
      <Main open={drawerOpen} drawerwidth={drawerWidth} className="ucs-main" sx={{width: 'calc(100% - ' + drawerWidth + 'px)'}}>
        {props.children}
      </Main>
      <Menu
        anchorEl={userMenuAnchorEl}
        id="user-menu"
        open={openUserMenu}
        onClose={handleUserMenuClose}
        onClick={handleUserMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      > 
        <Box sx={{ padding: '0.5ex', paddingLeft: '1em', paddingRight: '1em'}}>
        <Box>
          <Typography>
            {UserInfo.info.givenName + ' ' + UserInfo.info.familyName}
          </Typography>
        </Box>
        <Box>
          <Typography>
            {networkUser.email}
          </Typography>
        </Box>
        </Box>
        <Divider/>
        <MenuItem key="pref" onClick={handlePrefClick} >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Preferences
        </MenuItem>
        <MenuItem key="otp" >
          <ListItemIcon>
             <QrCode2Icon fontSize="small" />
          </ListItemIcon>
          Reset OTP
        </MenuItem>
        <Divider/>
        <MenuItem key="logout" onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={appMenuAnchorEl}
        id="app-menu"
        open={openAppMenu}
        onClose={handleAppMenuClose}
        onClick={handleAppMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      > 
        <Box sx={{ padding: '0.5ex', paddingLeft: '1em', paddingRight: '1em', width: '22em', minHeight: '15em', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            {Modules.map( (module, index) => {
              
              
              return (

                <MenuItem key={'mod' + index} component={RouterLink} to={module.route.path} 
                  sx={{ display: moduleMenuAccess[module.name] && moduleMenuAccess[module.name].hide ? "none" : "block", width: "6em" }} 
                  disabled={moduleMenuAccess[module.name] && moduleMenuAccess[module.name].disabled} >
                  <ListItemIcon sx={{ display: "block", textAlign: "center", fontSize: "2.8em" }}>
                    {module.icon}
                  </ListItemIcon>
                  <Typography align="center" sx={{ fontSize: '12px' }}>{module.label}</Typography>
                </MenuItem>
              );
            }
            )}
        </Box>
      </Menu>

      <PreferencesDialog
          open={prefOpen}
          onClose={handlePrefClose}
      />
    </Box>
  );
}

export { App, MessageHooks, Notifications, Modules, processServerMessage, router };

/* Save
<MenuItem key={'mod' + index} onClick={() => handleAppModuleClick(module, index)} component={RouterLink} sx={{display: "block", width: "6em"}}>
                <ListItemIcon sx={{display: "block", textAlign: "center", fontSize: "2.8em"}}>
                  {module.icon}
                </ListItemIcon>
                <Typography align="center" sx={{fontSize: '12px'}}>{module.label}</Typography>
              </MenuItem>

*/
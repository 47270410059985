import React, { Component, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch, useStore } from 'react-redux';
// import store from 'app/store';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import debounce from 'lodash.debounce';
import { setCurrentMenu } from 'features/menu/menuSlice';
import postal from 'postal';
import { UserInfo, netGet, netPost } from "../network";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SchemaIcon from '@mui/icons-material/Schema';
import SearchIcon from '@mui/icons-material/Search';

var wreload = 0;

function WorkflowFrontMenu (props) {
    const {
        reload=0,
        ...other
    } = props;

    return (
        <Box sx={{marginTop: '16px', textAlign: 'center'}}>
            <FormatListBulletedIcon/>
        </Box>
    );

};

function WorkflowFrontList (props) {

    const [workNodes, setWorkNodes] = useState([]);
    const [docList, setDocList] = useState([]);
    
    const CELL_PADDING = '0.8ex';

    useEffect(() => {
        updateInstances();
    },[]);

    const updateInstances = () => {
        // console.log('Fetching workflow instances... ' + reload);
        netGet('/api/doc/roots?sort=alpha')
            .then(response => response.json())
            .then(docs => {
                if (Array.isArray(docs)) {
                    // console.log('Got ' + docs.length + ' documents: ' + JSON.stringify(docs) );
                    setDocList(docs);
                    netGet('/api/workflow/inst/find/applicable')
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Status: ' + response.status);
                            }
                            return response.json();
                        })
                        .then(data => {
                            const wn = [];
                            const wl = [];

                            if (data && Array.isArray(data.active)) {
                                data.active.forEach( w => wl.push(w));
                            }
                            if (data && Array.isArray(data.mytasks)) {
                                data.mytasks.forEach( w => wl.push(w));
                            }
                            // sort by document and part
                            wl.sort( (a,b) => {
                                let d1 = a?.properties?.document;
                                if ( ! (typeof d1 === 'string')) {
                                    d1 = 'ZZZZ';
                                }
                                let d2 = b?.properties?.document;
                                if ( !(typeof d2 === 'string') ) {
                                    d2 = 'ZZZZ';
                                }
                                let r = d1.localeCompare(d2);
                                if ( r === 0 ) {
                                    const p1 = a?.properties?.part;
                                    const p2 = b?.properties?.part;
                                    if ( p1 && p2 ) {
                                        r = p2.number.localeCompare(p1.number);
                                    } else if ( p1 && !p2) {
                                        r = -1;
                                    } else if ( p2 && !p1) {
                                        r = 1;
                                    }
                                }
                                return r;
                            });
                            let cd = '';
                            let cn = null;
                            let cp = null;
                            let cpn = '';
                            let otherParentNode = null;
                            const otherNodes = [];
                            const otherFlows = [];
                            const wNoDocs = [];
                            wl.forEach( w => {
                                const d = w.properties?.document;
                                console.log('DOC:  ' + d);
                                if ( typeof d === 'string' ) {
                                    const dd = docs.find( di => di.id === d);
                                    let dtitle = d;
                                    if ( dd ) {
                                        dtitle = dd.title;
                                    }
                                    console.log('  DOC TITLE: ' + dtitle);

                                    if (!cn || cd !== d) {
                                        cd = d;
                                        cn = {
                                            
                                            label: dtitle,
                                            id: d,
                                            key: d,
                                            style: {padding: 0},
                                            children: [],
                                            leaf: false,
                                            data: {
                                                label: dtitle,
                                                status: '',
                                                other: '',
                                                doc: d,
                                                docLabel: dtitle,
                                            },
                                        };
                                        wn.push(cn);
                                        cp = null;
                                        cpn = '';
                                    }
                                    if (cn) {
                                        let ititle = w.properties?.instanceName;
                                        if ( !ititle) {
                                            ititle = w.label;
                                        }
                                        if ( !ititle ) {
                                            ititle = '' + w.id;
                                        }
                                        let defName = w.properties?.definitionLabel;
                                        let title = ititle;
                                        if ( typeof defName === 'string' ) {
                                            title = defName + '\u2014' + ititle;
                                        } else {
                                            defName = '';
                                        }
                                        console.log('W TITLE: ' + title);
                                        const part = w.properties?.part;
                                        if ( part ) {
                                            if ( !cp || cpn !== part.number) {
                                                cp = {
                                                    id: d + '-' + w.id,
                                                    key: d + '-' + w.id,
                                                    label: part.number,
                                                    data: {
                                                        part: part,
                                                        doc: d,
                                                        docLabel: dtitle,
                                                        ititle: ititle,
                                                        dtitle: defName,
                                                        label: part.number,
                                                        status: '',
                                                        other: '',
                                                    },
                                                    children: [],
                                                    leaf: false,
                                                };
                                                cn.children.push(cp);
                                                cpn = part.number;
                                            }
                                            cp.children.push({
                                                id: d + '-' + w.id + '-' + part.number,
                                                key: d + '-' + w.id + '-' + part.number,
                                                label: title,
                                                data: {
                                                    instance: w,
                                                    part: part,
                                                    doc: d,
                                                    docLabel: dtitle,
                                                    ititle: ititle,
                                                    dtitle: defName,
                                                    label: title,
                                                    status: 'OK',
                                                    other: '',
                                                },
                                                children: [],
                                                leaf: true,
                                            });

                                        } else {
                                            cn.children.push({
                                                id: d + '-' + w.id,
                                                key: d + '-' + w.id,
                                                label: title,
                                                data: {
                                                    instance: w,
                                                    doc: d,
                                                    docLabel: dtitle,
                                                    ititle: ititle,
                                                    dtitle: defName,
                                                    label: title,
                                                    status: 'OK',
                                                    other: '',
                                                },
                                                children: [],
                                                leaf: true,
                                            });
                                            cp = null;
                                            cpn = '';
                                        }
                                    }

                                } else {
                                    wNoDocs.push(w.id);
                                    otherFlows.push(w);
                                    if ( !cn || cd !== ' ') {
                                        cd = ' ';
                                        cn = {
                                            id: 'wn-0',
                                            key: 'wn-0',
                                            children: [],
                                            leaf: false,
                                            data: {
                                                doc: ' ',
                                                docLabel: 'System',
                                                label: 'Other',
                                                status: '',
                                                other: '',
                                            }
                                        };
                                        otherParentNode = cn;
                                        wn.push(cn);
                                    }
                                    /*
                                    if (cn) {
                                        let ititle = w.properties?.instanceName;
                                        if ( !ititle) {
                                            ititle = w.label;
                                        }
                                        if ( !ititle ) {
                                            ititle = '' + w.id;
                                        }
                                        let defName = w.properties?.definitionLabel;
                                        let title = ititle;
                                        if ( typeof defName === 'string' ) {
                                            title = defName + '\u2014' + ititle;
                                        } else {
                                            defName = '';
                                        }
                                        otherNodes.push({
                                            label: title,
                                            id: d + '-' + w.id,
                                            key: d + '-' + w.id,
                                            children: [],
                                            leaf: true,
                                            data: {
                                                instance: w.id,
                                                doc: ' ',
                                                docLabel: 'System',
                                                label: title,
                                                status: 'OK',
                                                other: '',
                                                ititle: ititle,
                                                dtitle: defName,
                                            }
                                        });
                                    }
                                    */
                                }
                            });
                            if ( otherParentNode ) {
                                const req = {
                                    type: 'document',
                                    instances: wNoDocs,
                                };
                                netPost('/api/workflow/inst/info', req)
                                    .then(response => {
                                        if (!response.ok) {
                                            throw new Error('Status: ' + response.status);
                                        }
                                        return response.json();
                                    }).then(data => {
                                        const idocs = data.documents;
                                        const info = data.info;
                                        if ( Array.isArray(idocs) ) {
                                            idocs.forEach(docName => {
                                                let dtitle = docName;
                                                const winsts = info[docName];
                                                if ( Array.isArray(winsts) ) {
                                                    let dcn = wn.find( n => n.id === docName);
                                                    if ( !dcn ) {
                                                        const dd = docs.find(di => di.id === docName);
                                                        
                                                        if (dd) {
                                                            dtitle = dd.title;
                                                        }
                                                        dcn = {                                            
                                                            label: dtitle,
                                                            id: docName,
                                                            key: docName,
                                                            children: [],
                                                            leaf: false,
                                                            data: {
                                                                label: dtitle,
                                                                status: '',
                                                                other: '',
                                                                doc: docName,
                                                                docLabel: dtitle,
                                                            },
                                                        };
                                                        wn.push(dcn);
                                                        cp = null;
                                                        cpn = '';
                                                    }
                                                    if (dcn) {
                                                        winsts.forEach(wid => {
                                                            const i = otherFlows.findIndex(wii => wii.id === wid);
                                                            if ( i>=0 ) {
                                                                const iw = otherFlows[i];
                                                                otherFlows.splice(i, 1);
                                                                let ititle = iw.properties?.instanceName;
                                                                if (!ititle) {
                                                                    ititle = iw.label;
                                                                }
                                                                if (!ititle) {
                                                                    ititle = '' + iw.id;
                                                                }
                                                                let defName = iw.properties?.definitionLabel;
                                                                let title = ititle;
                                                                if (typeof defName === 'string') {
                                                                    title = defName + '\u2014' + ititle;
                                                                } else {
                                                                    defName = '';
                                                                }
                                                                console.log('W TITLE: ' + title);
                                                                const part = iw.properties?.part;
                                                                if (part) {
                                                                    if (!cp || cpn !== part.number) {
                                                                        cp = {
                                                                            id: docName + '-' + iw.id,
                                                                            key: docName + '-' + iw.id,
                                                                            label: part.number,
                                                                            data: {
                                                                                part: part,
                                                                                doc: docName,
                                                                                docLabel: dtitle,
                                                                                ititle: ititle,
                                                                                dtitle: defName,
                                                                                label: part.number,
                                                                                status: '',
                                                                                other: '',
                                                                            },
                                                                            children: [],
                                                                            leaf: false,
                                                                        };
                                                                        dcn.children.push(cp);
                                                                        cpn = part.number;
                                                                    }
                                                                    cp.children.push({
                                                                        id: docName + '-' + iw.id + '-' + part.number,
                                                                        key: docName + '-' + iw.id + '-' + part.number,
                                                                        label: title,
                                                                        data: {
                                                                            instance: iw,
                                                                            part: part,
                                                                            doc: docName,
                                                                            docLabel: dtitle,
                                                                            ititle: ititle,
                                                                            dtitle: defName,
                                                                            label: title,
                                                                            status: 'OK',
                                                                            other: '',
                                                                        },
                                                                        children: [],
                                                                        leaf: true,
                                                                    });

                                                                } else {
                                                                    dcn.children.push({
                                                                        id: docName + '-' + iw.id,
                                                                        key: docName + '-' + iw.id,
                                                                        label: title,
                                                                        data: {
                                                                            instance: iw,
                                                                            doc: docName,
                                                                            docLabel: dtitle,
                                                                            ititle: ititle,
                                                                            dtitle: defName,
                                                                            label: title,
                                                                            status: 'OK',
                                                                            other: '',
                                                                        },
                                                                        children: [],
                                                                        leaf: true,
                                                                    });
                                                                    cp = null;
                                                                    cpn = '';
                                                                }
                                                            }

                                                        });
                                                    }
                                                }
                                            });
                                            wn.forEach(n => {
                                                if (Array.isArray(n.children)) {
                                                    n.children.sort((a, b) => {
                                                        let r = a.data.ititle.localeCompare(b.data.ititle);
                                                        if (r === 0) {
                                                            r = a.data.dtitle.localeCompare(b.data.dtitle);
                                                        }
                                                        return r;
                                                    });
                                                }
                                            });
                                            // console.log('WORK NODE MODEL: ' + JSON.stringify(wn));
            
                                            setWorkNodes(wn);
                                        }
                                    }).catch(error => {
                                        console.log('Error: ' + error);
                                    });
                            } else {
                                wn.forEach(n => {
                                    if (Array.isArray(n.children)) {
                                        n.children.sort((a, b) => {
                                            let r = a.data.ititle.localeCompare(b.data.ititle);
                                            if (r === 0) {
                                                r = a.data.dtitle.localeCompare(b.data.dtitle);
                                            }
                                            return r;
                                        });
                                    }
                                });
                                // console.log('WORK NODE MODEL: ' + JSON.stringify(wn));

                                setWorkNodes(wn);
                            }

                        }).catch(error => {
                            console.log('Error: ' + error);
                        });

                }
            }).catch(error => {
                console.log('Error fetching document list: ' + error);
            });
        
    };


    const drawtable = () => {
        /*
        return (
            <TreeTable value={workNodes} tableStyle={{ width: '100%' }} emptyMessage={<span></span>}
                    selectionMode="single" selectionKeys={selectedNodeKey} onSelectionChange={handleSelectedNode} metaKeySelection={false} scrollable scrollHeight="250px">
                    <Column field="name" header={itemHeaderLabel} alignHeader="left" style={{ padding: 0, paddingLeft: '1ex', textAlign: 'left', width: '70%' }}
                        headerStyle={{ padding: '0.9ex', width: '70%', fontSize: '90%' }} expander ></Column>
                    <Column field="def" header="Default Value" align="center" headerStyle={{ padding: '0.9ex', fontSize: '90%' }}></Column>
                    <Column field="other" header="Other Field" align="center" headerStyle={{ padding: '0.9ex', fontSize: '90%' }}></Column>
                </TreeTable>
        );
        */
    };

    const handleSelectedNode = () => {

    }

    return (
        <Box sx={{padding: 2, height: '90%'}}>
            <Box sx={{ padding: 1, textAlign: 'center' }}>
                <TextField
                    id="wfl-search"
                    type="search"
                    size="small"
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }
                    }}
                    sx={{ minWidth: '12em' }}
                />
            </Box>
            <Box sx={{minHeight: '80%'}}>
                <TreeTable value={workNodes} tableStyle={{ width: '100%', height: '100%' }} emptyMessage={<span></span>}
                    selectionMode="single" onSelectionChange={handleSelectedNode} metaKeySelection={false} scrollable scrollHeight="100%"
                    defaultSortOrder={0} rowHover >
                    <Column field="label" header="Document/Workflow" alignHeader="left" style={{ padding: 0, paddingLeft: '1ex', textAlign: 'left', width: '60%' }}
                        headerStyle={{ padding: '0.9ex', width: '60%', fontSize: '90%' }} 
                        bodyStyle={{textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '60%', padding: CELL_PADDING,}}
                        expander ></Column>
                    <Column field="status" header="Status" align="center" headerStyle={{ padding: '0.9ex', fontSize: '90%', textAlign: 'center', }} bodyStyle={{padding: CELL_PADDING}}></Column>
                    <Column field="other" header="Other" align="center" headerStyle={{ padding: '0.9ex', fontSize: '90%', textAlign: 'center' }} bodyStyle={{padding: CELL_PADDING}}></Column>
                </TreeTable>
            </Box>
        </Box>
    );

}


const WorkflowFrontModule = {
    // label
    name: 'workfront',
    label: 'Work Front',
    barLabel: 'Uniscope Cloud Portal',
    barShortLabel: 'UCS',
    // icon
    icon: <SchemaIcon fontSize="inherit" />,
    // menu
    drawerContent: <WorkflowFrontMenu reload={wreload}/>,
    // initial body page
    pageContent: <WorkflowFrontList/>,
    // send new body page on menu actions
    pageChange: (page) => {},
    pageIndexChange: (index) => {},
    drawerChange: (drawer) => {},
    drawerOpen: (open) => {},
    menuIconChange: (icon) => {},
    onMenuIconClick: (event) => {},
    drawerWidth: 50,

    hooks: {}, // { workflow: { top: workflowInfo } },
    route: 
        {
            path: 'workfront',
            element: <WorkflowFrontMenu reload={wreload}/>,
        }
    ,
    aclPaths: [ 
        {path: '/C/workfront', label: 'Workflow'}, 
        {path: '/C/workfront/instance', label: 'Instances'}, 
        {path: '/C/workfront/available', label: 'Available'},
        {path: '/C/workfront/completed', label: 'Completed'}
    ],
};

export { WorkflowFrontModule as default};